import React, {useEffect, useRef, useState} from "react";
import BodyContainer from "../Common/BodyContainer/BodyContainer";
import {getCarByCarName, getSalesEmployeeByBrand} from "../HTTPS/apisInterface";
import { makeStyles } from '@material-ui/core/styles';
import Employee from "../Employee/Employee";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import nl from "date-fns/locale/nl-BE";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import {useHistory} from 'react-router-dom';
import NotFound from "../NotFound/NotFound";
import CircleProgressWithLabel from "../Common/CircleProgress/CircleProgressWithLabel";
import Lottie from "react-lottie";
import busyAnimation from './busyschedule.json';

const useStyles = makeStyles({
	table: {
		minWidth: 'unset',
	},
});
const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: busyAnimation,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
};

export default function (props) {
	const history = useHistory();
	const classes = useStyles();
	const [employees,setEmployees] = useState([]);
	const [alert,setAlertView] = useState('hidden');
	const extraDayInMilliSeconds = 24 * 60 * 60 * 1000;
	const [dateText,setDateText]= useState(new Date(new Date().getTime() + extraDayInMilliSeconds).toLocaleDateString('nl-BE',{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }))
	const [disbaledDatePicker,setDisabledDatePicker] = useState(true);
	const [selectedDate, setSelectedDate] = useState(new Date(new Date().getTime() + extraDayInMilliSeconds));
	const [form,setForm] = useState(false);
	const [loading,setLoading] = useState(true);
	const [message,setMessage] = useState({message:'',status:'success'});
	const [carData,setCarData]= useState(props.carData);
	const [stopLoadingProgressBar,setStopLoadingProgressBar]=useState(false);
	const firstUpdate = useRef(true);

	const handleDateChange = (date) => {
		if(disbaledDatePicker){
			return
		}
		if(date == "Invalid Date" || date == null){
			return ;
		}

	if(date.toISOString().split("T")[0] < new Date(Date.now()).toISOString().split("T")[0]){
			//Old Date
			setMessage({message: `Date can't be selected`,status: 'error'})
			setAlertView('visible');
		setSelectedDate(date);
			setTimeout(()=>{setAlertView('hidden')},1500);
			setLoading(false);
			return;
		}
	  setDisabledDatePicker(true);
		props.reactGA.event({
			category: "Booking Calendar",
			action: "Change Date",
			label:date.toLocaleDateString('nl-BE',{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })
		})
		date.setHours(new Date().getHours());
		setDateText(date.toLocaleDateString('nl-BE',{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }));
		setSelectedDate(date);
		const formattedDate = `${date.getFullYear()}-${('0'+(date.getMonth()+1)).slice(-2)}-${('0'+date.getDate()).slice(-2)}`
		setLoading(true);
		setStopLoadingProgressBar(false);
		setMessage({message:`Date changed to ${date.getDate() + "/" +(date.getMonth()+1) +"/"+date.getUTCFullYear()}`,status: 'success'})
		setAlertView('visible');
		setTimeout(()=>{setAlertView('hidden')},1500);
		getSalesEmployeeByBrand(props.brand.currentLocationFullName,props.brand.currentCarName,formattedDate,props.brandCondition).then(response=>{
				setEmployees(null);
			setSelectedDate(date);
				setTimeout(()=>{	setEmployees(typeof response != "object" ? [] : response);},400)
			setStopLoadingProgressBar(true);
			setDisabledDatePicker(false);
		})

	};
	useEffect(()=>{

		if(Object.keys(carData).length === 0){
			const url = history.location.pathname.split("/");
			const carName = url[url.length -1];
			if(carName !== "aanbodBMW" && carName !== "aanbodMINI"){
				getCarByCarName(carName).then(response=>{
					if(!response){
						setCarData(null);
					}else{
						setCarData(response);
					}
				})
			}
			}

		const formattedDate = `${selectedDate.getFullYear()}-${('0'+(selectedDate.getMonth()+1)).slice(-2)}-${('0'+selectedDate.getDate()).slice(-2)}`;
		getSalesEmployeeByBrand(props.brand.currentLocationFullName,props.brand.currentCarName,formattedDate,props.brandCondition).then(response=>{
			setEmployees(typeof response != "object" ? []:response);
			setStopLoadingProgressBar(true);
			setDisabledDatePicker(false)
		})
	},[props.brand])
	useEffect(() => {
		if(firstUpdate.current){
			firstUpdate.current = false;
		}
		else{
			setTimeout(()=>{setLoading(!stopLoadingProgressBar)},200);
		}
	}, [stopLoadingProgressBar]);

return(
	 <React.Fragment>
		 <BodyContainer {...props} childComponent={<React.Fragment>
			 <div  className={'col'}>
				 <React.Fragment>
					 <TableContainer style={{marginTop:'15px'}} component={Paper}>
						 <Table className={classes.table} aria-label="simple table">
							 <TableHead>
								 <TableRow>
									 <TableCell rowSpan={3} colSpan={3}>
										 <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
											 <Grid container justify="space-between" alignItems="center">
												 <KeyboardDatePicker
														autoOk={true}
														disabled={disbaledDatePicker}
														minDate={new Date()}
														allowKeyboardControl={false}
														disablePast={true}
														variant="inline"
														format="dd/MM/yyyy"
														margin="normal"
														id="date-picker-inline"
														label="Datum Kiezer"
														value={selectedDate}
														onChange={handleDateChange}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
												 />
												 <React.Fragment>
													 <div>
														 <Button style={{display:'none'}} onClick={() => {
															 if (selectedDate.getDate() != new Date(Date.now()).getDate()) {
																 handleDateChange(new Date(Date.now()));
																 setAlertView('visible');
																 setTimeout(() => {
																	 setAlertView('hidden')
																 }, 1500);
															 }

														 }} variant="contained" color="primary">
															 Vandaag
														 </Button></div>
													 <Typography style={{textTransform: "capitalize", fontWeight: 'bolder'}} component="div">
														 {dateText}
													 </Typography></React.Fragment>

											 </Grid>
										 </MuiPickersUtilsProvider></TableCell>
								 </TableRow>
							 </TableHead>
						 </Table>
					 </TableContainer>
					 {carData != null ? loading ? <div style={{display:'flex',alignItems:'center',flexDirection:"column",marginTop:'80px'}}><h5>Beschikbaarheden verkopers ophalen</h5><CircleProgressWithLabel stopProgress={stopLoadingProgressBar} value={0} /></div>:
							<Grid  justify="flex-start" alignItems="baseline" style={{marginTop:'20px'}} container spacing={4}>
						 {employees !== null && employees.map((row) => (
								<Grid key={row.name} item xs={12} sm={6} md={4} lg={3} >
									<Employee reactGA={props.reactGA} enableForm={setForm}  {...props} carData={carData} date={selectedDate} employee={row}/>
								</Grid>
						 ))}
								{employees!== null && employees.length === 0 &&  <div className={'container'} style={{marginLeft:"auto",marginRight:"auto"}}>
									<p style={{fontWeight:'bolder',textAlign:'center',marginTop:'20px'}}>Het is niet meer mogelijk om op deze dag een online afspraak te maken.</p><p style={{fontWeight:'bolder',textAlign:'center',marginTop:'10px'}}>Kom gerust langs in één van onze showrooms, onze verkopers staan voor jou klaar.</p>
									<Lottie
										 options={defaultOptions}
										 height={400}
										 width={400}
									/>
								</div>}
					 </Grid> :<NotFound message={"Geen wagen van dit type gevonden."}/>}
				 </React.Fragment>

			 </div>
		 </React.Fragment>}/>
		 <Alert   style={{width:'fit-content',position:'absolute',top:'10px',left:'0',right:'0',marginLeft:'auto',marginRight:'auto',visibility:alert}} severity={message.status}>{message.message}</Alert>
	 </React.Fragment>
)
}
