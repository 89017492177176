import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {carBrands} from "../../AppConstants";


export default function (props) {

	const useStyles = makeStyles({
		root: {
			maxWidth: 345,
		},
	});
	const classes = useStyles();
	const history = useHistory();

	return (
		 <Card style={props.style} className={classes.root}>
			 <CardActionArea onClick={()=>{
			 	if(props.isClickable === false)
				{
					return;
				}
			 	const carName = props.carObject.name.toLowerCase().split(" ").join("-");
				 props.reactGA.event({
					 category: "Car",
					 action: "Select",
					 label:props.carObject.name
				 })
				history.push(`${history.location.pathname}/${carName}${history.location.search}`,{carData:props.carObject})
			 }}>
				 <CardMedia
						component="img"
						alt={props.carObject.name}
						height="140"
						style={{objectFit:"contain"}}
						image={props.carObject.image}
						title={props.carObject.name}
				 />
				 <CardContent>
					 <Typography gutterBottom variant="h5" component="h2">
						 {props.carObject.name}
					 </Typography>
					 {props.brandCondition ? <Typography variant="body2" color="textSecondary" component="p">
						 {props.brandCondition =="new" ? "New" :props.brandCondition == "used" ? "Used":"Fleet"}
					 </Typography> :''}

				 </CardContent>
			 </CardActionArea>
		 </Card>
	);
}
