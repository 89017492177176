import axios from 'axios';
const backendURL = "https://booky-cms.purplepanda.be/";
const namespace = "/wp-json/pp-json";
const url = backendURL + namespace;
let cancelToken;

const get = async (uri)=>{
	const res = await axios.get(`${url}/${uri}`);
	return res.data;
}
const post = async (uri,data)=>{
	console.log(cancelToken)
	const res = await  axios.post(`${url}/${uri}`,JSON.stringify(data),{headers: { 'content-type': 'application/x-www-form-urlencoded' }});
	return res.data;
}

export const getBrands = async ()=>{
return get("getBrands")
}

export const getCarsByBrand = async (brandName,brandCondition)=>{
	return get(`getCars/${brandName}/${brandCondition}`);
}

export const getSalesEmployeeByBrand = async(brandLocationName,carName,dateParam= null,brandCondition)=>{
let	queryParam = "";
	if(dateParam != null){
		queryParam=`?date=${dateParam}`
	}
	//Check if there are any previous pending requests
	if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel("Operation canceled due to new request.");
	}
	cancelToken = axios.CancelToken.source();
	return (await axios.get(`${url}/getSalesEmployeesByLocation/${brandLocationName}/${carName}/${brandCondition}/${queryParam}`,{cancelToken:cancelToken.token})).data;
}

export const sendEmail = async (formData)=>{
	return post(`sendEmails`,formData);
}

export const checkEmployeeAppointment = async (formData)=>{
	return post(`isEmployeeBusy`,formData)
}
export const getCarByCarName = async (carName)=>{
	return get(`getCarByCarName/${carName}`);
}
export const getFooterData = async (brandName)=>{
	return get(`getFooterData/${brandName}`);
}
