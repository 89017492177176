import React, {useEffect, useRef, useState} from "react";
import BodyContainer from "../Common/BodyContainer/BodyContainer";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import CarComponent from "../CarList/CarComponent";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import {checkEmployeeAppointment, sendEmail} from "../HTTPS/apisInterface";
import Lottie from 'react-lottie';
import mailAnimation from './mailAnimation.json';
import {carBrands} from "../../AppConstants";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	root: {
	}
}));




export default function (props) {
	const isMobile = useMediaQuery('(max-width:766px)');
	const history = useHistory();
	const [image,setImage] = useState();
	const [alert,setAlertView]=useState('hidden');
	const [message,setMessage] = useState({status:"success",message:""});
	const [submitDisableButton,setDisableButton] = useState(false);

	useEffect(()=>{
		const img = new Image();
		img.src =  props.employee.image != false ? props.employee.image : '/assets/img/manplaceholder.png'; // by setting an src, you trigger browser download
		img.onload = () => {
			setImage(img.src);
		}
		const selectedDate = `${props.currentDate.getFullYear()}-${('0'+(props.currentDate.getMonth()+1)).slice(-2)}-${('0'+props.currentDate.getDate()).slice(-2)}`
		let isFound = false;
		setLoading(false);
		setShowPage(true)
/*		checkEmployeeAppointment({"email":props.employee.email,"start_date":selectedDate}).then((response)=>{
			setLoading(false);
			if(response["message"]){
				history.goBack();
			}
			else{
				response.map(item=>{
					if(item["start"] === props.timeSlot){
						setShowForm(false);
						setShowPage(true);
						isFound = true;
					}
				})
			}
			if(!isFound){
				setShowPage(true);
				setLoading(false);
			}
		})*/

	},[props.carData])
	const classes = useStyles();
	const StyledBadge = withStyles((theme) => ({
		badge: {
			backgroundColor: props.employee.period == null || props.employee.period.length === 0? '#b80c0c' :'#44b700',
			color: props.employee.period == null || props.employee.period.length === 0 ? '#b80c0c' :'#44b700',
			boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
			'&::after': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '50%',
				animation: `$ripple 1.2s ${props.employee.period === null || props.employee.period.length === 0	  ? 'once' : 'infinite'} ease-in-out`,
				border: '1px solid currentColor',
				content: '""',
			},
		},
		'@keyframes ripple': {
			'0%': {
				transform: 'scale(.8)',
				opacity: 1,
			},
			'100%': {
				transform: 'scale(2.4)',
				opacity: 0,
			},
		},
	}))(Badge);
	const options = [{value:true,label:"JA"},{value: false,label:"NEE"}];
	const [selectedOption,setSelectedOption] = useState('');
	const formRef = useRef(null);
	const formDataInit = {
		voornaam:null,
		naam:null,
		telefoonNummber:null,
		emailAdres:null,
		adres:null,
		huidingVoertuigNummerPlat:null,
		extraInfo:null
	}
	/*	proefritGewenst:null,*/
	const [formData,updateFormData] = useState(formDataInit);
	const [formErrors,setFormErrors] = useState({});
	const [showForm,setShowForm] = useState(true);
	const [showPage,setShowPage] = useState(false);
	const [acceptPrivacyPolicy,setPrivacyPolicy] = useState(false);
	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: mailAnimation,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};
	const validateTextField = (value)=>{
		if(value === null) return false;
		if(value === " ") return false;
		return true;
	}
	const validateEmailField = (value)=>{
		if(value == null) return false;
		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return emailPattern.test(value);
	}
	const [loading,setLoading] = useState(true);
	const sendEmails = ()=>{
		let errorsObject = {};
		Object.keys(formData).forEach(item=>{
			const validation =  item !='emailAdres' ? validateTextField(formData[item]) : validateEmailField(formData[item]);
			if(validation == false){
				if(item != 'adres' && item !='huidingVoertuigNummerPlat' && item != 'extraInfo'){
					errorsObject[item] = true;
				}
			}
		})
		setFormErrors(errorsObject);
		if(Object.keys(errorsObject).length === 0){
			setDisableButton(true);
			formData['locationName'] = props.brand.currentLocationFullName;
			formData['location'] = props.brand.currentLocationFullName;
			const extraHour = `${('0'+(parseInt(props.timeSlot.split(":")[0] ) + 1)).slice(-2)}:${props.timeSlot.split(":")[1]}`;
			let startEventDate = `${props.currentDate.toISOString().split("T")[0]}T${props.timeSlot}`;
			let endEventDate = `${props.currentDate.toISOString().split("T")[0]}T${extraHour}`;
			formData['startDate'] = startEventDate;
			formData['selectedDate'] =`${props.currentDate.toLocaleDateString('nl-BE',{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })} - ${props.timeSlot} -> ${extraHour} `;
			formData['endDate'] = endEventDate;
			formData['startTime'] = startEventDate;
			formData['endTime'] = endEventDate;
			formData['carName'] = props.carData.name;
			formData['employeeName'] = props.employee.name;
			formData['employeeEmail'] = props.employee.email;
			formData['carCondition']=props.brandCondition;
			formData['selectedDateIso'] = props.currentDate;
			formData['timePeriod'] = props.timeSlot;

			sendEmail(formData).then(response=>{
				if(response.statusEmployee == 202 && response.statusClient == 202 && response.statusReserved == 300){
					props.reactGA.pageview(window.location.pathname + window.location.search +"?success=true");
					props.reactGA.event({
						category: "Booking",
						action: "Form success"
					})
					history.push({search:"success=true",state:history.location.state})
					setShowForm(false);
					updateFormData(formDataInit);
					setDisableButton(false);
				}else{
					setAlertView('visible');
					setTimeout(()=>{
						setAlertView('hidden');
					},2400);
					setDisableButton(false);
					setMessage({message:"Error Failed to book please try again or contact support",status: "error"})
				}

			})
		}

	}
	return(<React.Fragment>
			 <BodyContainer {...props} childComponent={<React.Fragment>
				 { loading ? <div className={'col'}><div style={{display:'flex',justifyContent:'center',marginTop:'80px'}}><CircularProgress /></div></div> :showPage ? showForm ? <div style={{marginRight:'auto',marginLeft:'auto',paddingTop:'30px'}}>
					<h4>Bevestig uw afspraak</h4>
					 <div style={{flexDirection:isMobile ? 'column':'row'}} className={'col flex centerItems'}>
						 {isMobile ? <div>
							 {/*'col col-lg-4 col-md-6 col-sm-6*/}
							 <div  className={'form'} style={{paddingTop:'20px'}} >
								 <div className={'flex'}>
									 <Card style={{width:'100%',marginRight:'20px',background:`${props.brand.name === carBrands.bmw.title ?'linear-gradient(180deg, #007BFF 50%,white 50%)' :'linear-gradient(180deg, #EC9603 50%,white 50%)'}`}} className={classes.root}>
										 <CardActionArea >
											 <div className={"imageContainer"}>
												 <CardMedia
														component="img"
														alt={props.employee.name}
														height="150px"
														id={`${props.employee.name.split(" ").join('')}`}
														style={{objectFit:"cover",width:'150px',borderRadius:'50%'}}
														image={image}
														title={props.employee.name}
												 />
												 <StyledBadge
														overlap="circle"
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'right',
														}}
														style={{position:'absolute',right:'35%',bottom:'55%'}}
														variant="dot"
												 />
											 </div>

											 <CardContent>
												 <Typography gutterBottom variant="h5" component="h2">
													 {props.employee.name}
												 </Typography>
												 <Typography variant="body2"  component="p">
													 Locatie : {props.brand.currentLocationFullName}
												 </Typography>
												 <Typography variant="body2"  component="p">
													 Vanaf : {props.currentDate.toLocaleDateString('nl-BE',{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })} - {props.timeSlot}
												 </Typography>
											 </CardContent>
										 </CardActionArea>
									 </Card>
									 <CarComponent brandCondition={props.brandCondition} isClickable={false}  style={{width:'100%',maxWidth:'unset'}} carObject={props.carData}/>
								 </div>
							 </div>
						 </div>: <div style={{marginRight:'41px'}} className={''}>
							 {/*'col col-lg-4 col-md-6 col-sm-6*/}
							 <div style={{maxWidth:'256px'}} className={'form'}>
								 <div className={'flex flex-column'}>
									 <Card style={{background:`${props.brand.name === carBrands.bmw.title ?'linear-gradient(180deg, #007BFF 50%,white 50%)' :'linear-gradient(180deg, #EC9603 50%,white 50%)'}`}} className={classes.root}>
										 <CardActionArea >
											 <div className={"imageContainer"}>
												 <CardMedia
														component="img"
														alt={props.employee.name}
														height="110px"
														id={`${props.employee.name.split(" ").join('')}`}
														style={{objectFit:"cover",width:'110px',borderRadius:'50%'}}
														image={image}
														title={props.employee.name}
												 />
												 <StyledBadge
														overlap="circle"
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'right',
														}}
														style={{position:'absolute',right:'93px',bottom:'120px'}}
														variant="dot"
												 />
											 </div>

											 <CardContent>
												 <Typography gutterBottom variant="h5" component="h2">
													 {props.employee.name}
												 </Typography>
												 <Typography variant="body2"  component="p">
													 Locatie : {props.brand.currentLocationFullName}
												 </Typography>
												 <Typography variant="body2"  component="p">
													 Vanaf : {props.currentDate.toLocaleDateString('nl-BE',{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })} - {props.timeSlot}
												 </Typography>
											 </CardContent>
										 </CardActionArea>
									 </Card>
									 {Object.keys(props.carData).length > 0 &&  <CarComponent brandCondition={props.brandCondition} isClickable={false}  style={{maxWidth:'unset',marginTop:'30px'}} carObject={props.carData}/>}
								 </div>
							 </div>
						 </div>}
						 <div className={''}>
							 {/*col col-lg-8 col-md-6 col-sm-7*/}
							 <div className={'form'}>
								 <div className={'formContainer'}>
									 <form  ref={formRef}  className={'container'}  autoComplete="on">
										 <h4>Persoonlijke gegevens</h4>
										 <FormControl error={formErrors.voornaam}>
											 <TextField error={formErrors.voornaam} name={"voornaam"} onChange={handleChange} required label="Voornaam" />
											 {formErrors.voornaam ? <FormHelperText >Please enter your first name</FormHelperText>:''}
										 </FormControl>
										 <FormControl error={formErrors.naam}>
											 <TextField error={formErrors.naam} name={'naam'} onChange={handleChange} required  label="Naam" />
											 {formErrors.naam ? <FormHelperText >Please enter your last/mid name</FormHelperText>:''}
										 </FormControl>
										 <FormControl error={formErrors.telefoonNummber}>
											 <TextField error={formErrors.telefoonNummber} name={'telefoonNummber'} onChange={handleChange} type="number" required  label="Telefoon Nummer" />
											 {formErrors.telefoonNummber ? <FormHelperText >Please enter your phone</FormHelperText>:''}
										 </FormControl>
										 <FormControl error={formErrors.emailAdres}>
											 <TextField error={formErrors.emailAdres} name={'emailAdres'} onChange={handleChange} required  label="E-mailadres " />
											 {formErrors.emailAdres ? <FormHelperText >Please Enter Valid Email Address</FormHelperText>:''}
										 </FormControl>
										 <TextField error={formErrors.adres} name={'adres'} onChange={handleChange} label="Adres" />
										 <TextField error={formErrors.huidingVoertuigNummerPlat} name={'huidingVoertuigNummerPlat'} onChange={handleChange} label={"Huidig voertuig + nummerplaat"}/>
										 {/*<TextField
												error={formErrors.proefritGewenst}
												id="standard-select-currency"
												select
												required
												label="Proefrit gewenst"
												name={'proefritGewenst'}
												value={selectedOption}
												onChange={(event)=>{setSelectedOption(event.target.value);
													handleChange(event);
												}}
										 >
											 {options.map((option) => (
													<MenuItem key={option.value} value={option.value}>
														{option.label}
													</MenuItem>
											 ))}
										 </TextField>*/}
										 <TextField onChange={handleChange}
																id="outlined-multiline-static"
																label="Extra Info"
																multiline
																rows={4}
																defaultValue={props.urlSearchParam ? `Ik ben geïnteresseerd in volgende wagen: \n\r ${props.urlSearchParam}`:null}
																error={formErrors.extraInfo}
																name={"extraInfo"}
																variant="outlined"
										 />
										 <FormControlLabel
												control={<Checkbox color="primary" checked={acceptPrivacyPolicy} onChange={()=>{ setPrivacyPolicy(!acceptPrivacyPolicy)}} name="checkedA" />}
												label={<span style={{color:'black'}} >Ik ga akkoord met de <a target={"_blank"} href={'https://jorssen.be/bmw/privacy-policy/'}>Privacy Policy</a></span>}
										 />


										 <Button  disabled={!acceptPrivacyPolicy || submitDisableButton} onClick={()=>{

											 sendEmails()
										 }} variant="contained" color="primary">
											 Submit {submitDisableButton ? <CircularProgress style={{marginLeft:"10px"}} size={20} />:''}
										 </Button>

									 </form>
								 </div>
							 </div>
						 </div>
					 </div>
				 </div>:<div className={'col'} style={{textAlign:'center',color:"black"}}>
					 <Lottie
							options={defaultOptions}
							height={400}
							width={400}
					 />
					 <h3>Uw aanvraag is verzonden.</h3>
					 <h3>U ontvangt zo dadelijk een bevestiging via email.</h3>
				 </div> :''}

			 </React.Fragment>}/>
		<Alert   style={{width:'fit-content',position:'absolute',top:'18%',left:'0',right:'0',marginLeft:'auto',marginRight:'auto',visibility:alert}} severity={message.status}>{message.message}</Alert>
		 </React.Fragment>)
}
