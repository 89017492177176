export const lightTheme = {
    body: '#F2F2F2',
    text: '#363537',
    toggleBorder: '#FFF',
    gradient: 'linear-gradient(#39598A, #79D7ED)',
}
//EC9603
export const darkTheme = {
    body: '#000',
    text: '#ffff',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#091236, #1E215D)',
}
